/* .blog-image {
	width: 100%;
	height: auto;
	max-height: 300px;
	object-fit: cover;
}

.blog-details-card {
	background: #d9e8f4;
	border-radius: 0px 0px 20px 20px;
	padding: 30px 50px;
} */

.blog-container {
	max-width: 900px;
	margin: 0 auto;
	padding: 20px;
}

.blog-header {
	text-align: center;
	margin-bottom: 30px;
}

.blog-h1 {
	font-size: 2.5em;
	color: #212529;
}

.download-button {
	display: inline-block;
	background-color: #ff5630;
	color: white;
	padding: 10px 20px;
	text-decoration: none;
	font-weight: bold;
	border-radius: 4px;
	margin-top: 20px;
}

.author-section {
	display: flex;
	align-items: center;
	justify-content: space-between;
	border-top: 1px solid #ccc;
	border-bottom: 1px solid #ccc;
	padding: 15px 0;
}

.author-image {
	width: 50px;
	height: 50px;
	border-radius: 50%;
}

.author-details {
	display: flex;
	align-items: center;
	gap: 15px;
}

.author-name {
	font-size: 1.1em;
	color: #0077cc;
	text-decoration: none;
}

.publish-date {
	font-size: 0.9em;
	color: #666;
}

@media (max-width: 600px) {
	.author-section {
		flex-direction: column;
		align-items: center;
	}

	.author-details {
		flex-direction: column;
		align-items: center;
	}
}

.content {
	max-width: 700px;
	margin: 30px auto;
}

.content img {
	max-width: 100%;
  height: auto;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  margin: 16px 0;
  display: block;
  object-fit: cover;
}

.intro-text {
	font-size: 1.2em;
	margin-bottom: 20px;
	color: #212529;
}

.image-section {
	text-align: center;
	margin: 20px 0;
}

.responsive-image {
	max-width: 100%;
	height: auto;
	border-radius: 8px;
	box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.body-text {
	font-size: 1em;
	margin-bottom: 20px;
	line-height: 1.6;
	color: #333;
}

@media (max-width: 600px) {
	.content {
		padding: 10px;
	}

	.intro-text,
	.body-text {
		font-size: 1em;
	}
}
