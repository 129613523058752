.data-table-cell {
	white-space: nowrap;
	text-overflow: ellipsis;
	overflow: hidden;
	font-size: 11px;
}
@media (min-width: 576px) {
	div.data-table-cell {
		font-size: 11px;
	}
}

/* Medium screens (md) */
@media (min-width: 768px) {
	.data-table-cell {
		font-size: 12px;
	}
}

/* Large screens (lg) */
@media (min-width: 992px) {
	.data-table-cell {
		font-size: 13px;
	}
}

/* Extra large screens (xl) */
@media (min-width: 1200px) {
}
