html,
body {
	height: 100%;
	margin: 0;
}

.bg-home {
	background-image: url('./assets/images/bg-home.png');
	background-size: cover;
	background-position: center;
} /* Existing styles */
.navbar-size {
	font-size: 12px;
}

.custom-toggler {
	position: relative;
	border: none;
	outline: none;
}

.custom-toggler .toggler-icon {
	width: 30px;
	height: 3px;
	background-color: rgb(71, 148, 237);
	display: block;
	transition: all 0.3s ease;
	position: relative;
}

.custom-toggler.open .toggler-icon {
	background-color: transparent;
}

.custom-toggler.open .toggler-icon::before {
	transform: rotate(45deg) translate(5px, 5px);
}

.custom-toggler.open .toggler-icon::after {
	transform: rotate(-45deg) translate(5px, -5px);
}

.custom-toggler .toggler-icon::before,
.custom-toggler .toggler-icon::after {
	content: '';
	width: 30px;
	height: 3px;
	background-color: #007bff;
	display: block;
	position: absolute;
	left: 0;
	transition: all 0.3s ease;
}

.custom-toggler .toggler-icon::before {
	top: -8px;
}

.custom-toggler .toggler-icon::after {
	bottom: -8px;
}

.custom-toggler:focus {
	outline: none;
	box-shadow: none;
}

/* New styles for navbar collapse animation */
.navbar-collapse {
	transition: max-height 0.5s ease-out;
	max-height: 0;
	overflow: hidden;
}

.navbar-collapse.show {
	max-height: 500px; /* Adjust this value based on the content */
}

.font-2 {
	font-size: 12px;
	font-weight: 400;
	box-shadow: none; /* Removed !important */
}

.footer-font {
	font-size: 16px;
}

/* Button Styles */
button {
	box-shadow: none; /* Removed !important */
	display: flex;
	justify-content: center;
}

.search-button {
	border-radius: 50px;
	font-size: 13px;
}

/* Color Styles */
.light-primary {
	color: #007bff;
}

.text-1 {
	text-decoration: underline;
	font-size: 15px;
}

.font-1 {
	font-style: italic;
}
.bg-light-1 {
	background-color: #f0f3ff;
}

/* Input Styles */
.input-font,
.input-font-1,
input {
	font-size: 12px;
	border: none;
}

/* Card Styles */
.card-font {
	font-weight: lighter;
	font-size: 14px;
}

.card-font-1 {
	font-family: Sans-serif;
	font-weight: 550;
	font-size: 14px;
}

/* Hover Styles */
.font-color:hover {
	color: #f5f7fb;
}

/* Size Styles */
.question-mark {
	width: 10px;
	height: 10px;
	color: white;
	background-color: black;
	border: 2px solid black;
	border-radius: 50%;
	display: flex;
	justify-content: center;
	align-items: center;
	font-size: 12px;
}

/* Error Message Styles */
.error-message {
	color: red;
	font-size: 14px;
	margin-top: 10px;
}

/* Wrapper Styles */
.wrapper {
	display: flex;
	width: 100%;
	align-items: stretch;
}

/* Sidebar Styles */
.sidebar {
	transition: all 0.3s;
}

/* Content Styles */
.content {
	width: 100%;
	min-height: 100vh;
	transition: all 0.3s;
}

/* Responsive Styles */
@media (max-width: 768px) {
	.sidebar {
		margin-left: -250px;
	}

	.sidebar.active {
		margin-left: 0;
	}

	.sidebarCollapse span {
		display: none;
	}
}

.sidebar-height {
	height: 100%;
}

.sidebar {
	overflow-y: auto;
}
.sidebar::-webkit-scrollbar {
	width: 12px;
}

.sidebar::-webkit-scrollbar-track {
	background: #f1f1f1;
}

.sidebar::-webkit-scrollbar-thumb {
	background-color: #888;
	border-radius: 6px;
}

.sidebar::-webkit-scrollbar-thumb:hover {
	background: #555;
}

.link-hover {
	font-size: 14px;
}

.link-hover:hover {
	background-color: #007bff !important;
	color: white;
}

.logo-size {
	width: 50px;
	height: 50px;
	border-radius: 50%;
}

.active {
	background-color: #007bff !important;
	color: white !important;
	border-bottom: 0 !important;
}

input {
	box-shadow: none;
}

.nav-link:not(.nav-link:active) > .nav-item:hover {
	border-bottom: #007bff 1px solid !important;
}

.form-control:focus {
	box-shadow: none !important;
}

footer.mt-auto {
	margin-top: auto;
}

.navbar-collapse.show {
	overflow: visible;
}
