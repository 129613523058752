.job-details-card {
	background: #c4e1f4;
	border-radius: 20px;
	padding: 30px 10px;
}

.jobCard-title {
	color: #2a2448;
	font-size: 23px;
	font-weight: bold;
}
.job-det-table tbody tr th,
.job-det-table tbody tr td,
.job-det-table thead tr th {
	background-color: transparent;
	color: #2a2448;
	border: none;
}

/* Default font sizes (base on extra small - xs) */
.job-responsive-1 {
	width: 120px;
	font-size: 14px;
}
.job-responsive-2 {
	font-size: 15px;
	width: 141px;
}
/* Small screens (sm) */
@media (min-width: 576px) {
	.jobCard-title {
		font-size: 27px;
	}
	.job-responsive-1 {
		width: 153px;
		font-size: 16px;
	}
	.job-responsive-2 {
		width: 333px;
		font-size: 15px;
	}
}

/* Medium screens (md) */
@media (min-width: 768px) {
	.jobCard-title {
		font-size: 29px;
	}
	.job-responsive-1 {
		font-size: 17px;
		width: 155px;
	}
	.job-responsive-2 {
		width: 170px;
		font-size: 17px;
	}
}

/* Large screens (lg) */
@media (min-width: 992px) {
	.jobCard-title {
		font-size: 31px;
	}
	.job-responsive-1 {
		font-size: 18px;
		width: 171px;
	}
	.job-responsive-2 {
		font-size: 18px;
		width: 260px;
	}
}

/* Extra large screens (xl) */
@media (min-width: 1200px) {
	.jobCard-title {
		font-size: 32px;
	}
	.job-responsive-1 {
		width: 180px;
	}
	.job-responsive-2 {
		width: 346px;
	}
}
