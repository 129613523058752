.content-container {
	max-width: 900px;
	margin: 0 auto;
	padding: 10px;
}

.page-content {
	max-width: 700px;
	margin: 10px auto;
}

.page-content img {
	max-width: 100%;
	height: auto;
	border-radius: 8px;
	box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
	margin: 16px 0;
	display: block;
	object-fit: cover;
}

.intro-text {
	font-size: 1.2em;
	margin-bottom: 20px;
	color: #212529;
}

.image-section {
	text-align: center;
	margin: 20px 0;
}

.responsive-image {
	max-width: 100%;
	height: auto;
	border-radius: 8px;
	box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.body-text {
	font-size: 1em;
	margin-bottom: 20px;
	line-height: 1.6;
	color: #333;
}

@media (max-width: 600px) {
	.page-content {
		padding: 10px;
	}

	.intro-text,
	.body-text {
		font-size: 1em;
	}
}
